<!--
 * @Author: your name
 * @Date: 2021-05-11 20:29:30
 * @LastEditTime: 2021-05-28 16:50:01
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one2\src\views\skilllist\index.vue
-->
<template>
  <div class="allmsgclass">
    <navigation msg="技能云">
      <div @click="addskillmeth" class="addclass">
        <van-icon color="#F44C06" size=".38rem" name="add-o" />
        <div>增加技能</div>
      </div>
    </navigation>
    <div class="themsgclass2">
      <div class="jcla">工作技能</div>
      <div class="skillclass">
        <div class="fff" v-if="workskill.length > 0">
          <div
            @click="toskillmsg(item, '工作技能')"
            :class="item.status==1?'skilldiv2':'skilldiv'"
            v-for="(item, index) in workskill"
            v-bind:key="index"
          >
            {{ item.skill_name }}.{{item.count}}
          </div>
        </div>
        <div v-else><nomsgvue></nomsgvue></div>
      </div>
      <div class="jcla">生活技能</div>
      <div class="skillclass">
        <div class="fff" v-if="liftskill.length > 0">
          <div
            @click="toskillmsg(item, '生活技能')"
            :class="item.status==1?'skilldiv2':'skilldiv'"
            v-for="(item, index) in liftskill"
            v-bind:key="index"
          >
            {{ item.skill_name }}.{{item.count}}
          </div>
        </div>
        <div v-else><nomsgvue></nomsgvue></div>
      </div>
      <!-- <div class="jcla">其他技能</div>
      <div class="skillclass">
        <div class="fff" v-if="otherskill.length > 0">
          <div
            @click="toskillmsg(item, '其他技能')"
            :class="item.count>0?'skilldiv2':'skilldiv'"
            v-for="(item, index) in otherskill"
            v-bind:key="index"
          >
            {{ item.skill_name }}
          </div>
        </div>
        <div v-else><nomsgvue></nomsgvue></div>
      </div> -->
      <div class="jcla">综合技能</div>
      <div class="skillclass">
        <div class="fff" v-if="zdyskill.length > 0">
          <div
            @click="toskillmsg(item,'自定义技能')"
            :class="item.status==1?'skilldiv2':'skilldiv'"
            v-for="(item, index) in zdyskill"
            v-bind:key="index"
          >
            {{ item.position_name }}.{{item.count}}
          </div>
        </div>
        <div v-else><nomsgvue></nomsgvue></div>
      </div>
    </div>
  </div>
</template>
<script>
import nomsgvue from "../../components/nomsg/index";
export default {
  components: {
    nomsgvue,
  },
  data() {
    return {
      workskill: [], //工作技能
      liftskill: [], //生活技能
      otherskill: [], //其他技能
      zdyskill:[], //自定义技能
    };
  },
  mounted() {
    this.getmsg();
    this.getzidingy();
  },
  methods: {
    //查看自定义技能
    getzidingy() {
      this.$http.post("/firm/v1/Skillme/getCustom", {
        reqType: "userinfo",
        phone: atob(this.$route.query.phone),
      })
      .then(res=>{
         
        this.zdyskill = JSON.parse(res.data).data.custom;
      })
    },
    //添加技能
    addskillmeth() {
      this.$router.push({
        path: "/addskill",
        query: {
          phone: this.$route.query.phone,
        },
      });
    },
    toskillmsg(item, msg) {
      this.$router.push({
        path: "/skillmsg",
        query: {
          msg: JSON.stringify(item),
          skillmenu: msg,
        },
      });
    },
    getmsg() {
      this.$http
        .post("/firm/v1/Skillme/getSkill", {
          reqType: "userinfo",
          phone: atob(this.$route.query.phone),
        })
        .then((res) => {
           
          let allmsg = JSON.parse(res.data).data.skill_list;
          allmsg.forEach((e) => {
            if (e.skill_type == 1) {
              this.workskill.push(e);
            } else if (e.skill_type == 2) {
              this.liftskill.push(e);
            }
          });
        });
    },
  },
};
</script>
<style scoped>
.skillclass {
  margin-top: 0.4rem;
  margin-bottom: 0.76rem;
}
.fff {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.skilldiv {
  font-size: 0.28rem;
  line-height: 0.4rem;
  color: #222222;
  border: 1px solid #979797;
  padding: 0.2rem;
  border-radius: 0.4rem;
  margin-right: 0.2rem;
  margin-bottom: 0.23rem;
}
.skilldiv2 {
  font-size: 0.28rem;
  line-height: 0.4rem;
  color: #ff5f19;
  border: 1px solid #ff5f19;
  padding: 0.2rem;
  border-radius: 0.4rem;
  margin-right: 0.2rem;
  margin-bottom: 0.23rem;
}
.jcla {
  font-size: 0.42rem;
  line-height: 0.48rem;
  font-weight: 500;
}
.themsgclass2 {
  margin: 0.3rem 0.48rem;
}
.addclass > :first-child {
  margin-right: 0.14rem;
}
.addclass {
  display: flex;
  align-items: center;
  font-size: 0.34rem;
  line-height: 0.48rem;
  color: #333333;
}
.allmsgclass {
  background-color: #ffffff;
  min-height: 100%;
}
</style>
